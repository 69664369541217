import React, { useState, useEffect } from 'react'
import Layout from '../../../pages/Shipment/Layout';
import { ImageView } from '../../molecules/QualityControlMolecules/ImageView';
import { ProductInfo } from '../../molecules/QualityControlMolecules/ProductInfo';
import { t } from "../../../pages/Translations/TranslationUtils";
import { useNavigate } from 'react-router-dom';
import CommonShipmentServices from '../../../services/CommonShipmentServices';
import { getPhotoEditingUrl, photoEditingSaveUrl, fetchConfigData, fetchEach } from '../../../routes/ApiEndPoints'
import { APICALL } from '../../../services/ApiServices'
import Button from '../../atoms/Button';
import styles from "../../../pages/Shipment/shimpment.module.css";
import IconData from '../../../static/IconData';
import customAlert from '../../atoms/CustomAlert';
import Timer from '../../atoms/Timer';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import { WorkflowStageNames } from '../../../utils/constants/WorkflowStageNames';
import WorkflowItemInfo from '../../molecules/WorkflowItemInfo';

const PhotoEditingOrganism: React.FC = () => {
    const localdata = CommonShipmentServices.getPostData();
    const searchParams = new URLSearchParams(location.search);
    const serialNumber: any = searchParams.get("id");
    const navigate = useNavigate();
    const [photoEditingInfo, setPhotoEditingInfo] = useState<any>([]);
    const [stage, setStage] = useState<any>();
    const [running, setRunning] = useState(true);
    const [totaltime, setTotalTime] = useState(0);
    const [dataentry, setDataEntry] = useState<any>({})
    const [commentData, setCommentData] = useState({
        type: "renewal_record",
        type_id: null,
        stagename: "photo_editing",
        commentdata: {}
    });
    useEffect(() => {
        fetchPhotoEditingInfo();
    }, [serialNumber != undefined])

    const fetchPhotoEditingInfo = async () => {
        try {
            const postdata = {
                ...localdata,
                serialNumber: serialNumber
            }
            const response = await APICALL.service(getPhotoEditingUrl, 'POST', postdata);

            if (response?.status == 200) {
                const datarequest = {
                    "method": "POST",
                    "data": {
                        data: response?.data
                    }
                }
                const dataentryres = await APICALL.service(fetchEach, 'POST', datarequest, true);

                if (dataentryres?.status == 200) {
                    setDataEntry(dataentryres?.data);
                }
                setCommentData({ ...commentData, type_id: response?.data?.renewal_id, commentdata: response?.commentdata })
                let config = {
                    method: "POST",
                    data: {
                        type: "model",
                        name: "ProductType",
                        id: response?.data?.product_type_id
                    },
                };
                const configresponse: any = await APICALL.service(
                    fetchConfigData,
                    "POST",
                    config,
                    true
                );

                if (configresponse?.status === 200) {
                    let resdata = response?.data;
                    resdata.back_image = configresponse?.data.back_image ?? '';
                    resdata.front_image = configresponse?.data.front_image ?? '';
                    setPhotoEditingInfo(resdata);
                }
                else {
                    setPhotoEditingInfo(response?.data)
                }
                setStage(response?.data?.presentStageId)
            } else {
                customAlert(
                    "error",
                    t("Something went wrong while saving data"),
                    10000
                )
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (event: any) => {
        try {
            const postData = {
                ...localdata,
                serial_number: serialNumber
            }

            const response = await APICALL.service(photoEditingSaveUrl, 'PUT', postData);

            if (response?.status == 200) {
                navigate('/photo-editing/manage');
            } else {
                customAlert(
                    "error",
                    t("Something went wrong while saving data"),
                    10000
                )
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleBack = () => {
        navigate(`/photo-editing/manage`);
    }

    const handlePausePlay = async () => {
        try {
            let pause = await CommonShipmentServices.saveTimerApi(
                'renewal',
                commentData.type_id,
                localdata.user_id,
                WorkflowStageNames.PHOTO_EDITING,
                !running
            );
            pause && setRunning(!running);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (localdata?.user_id != null && commentData?.type_id != null) {
            timeTracker(true);

            window.addEventListener('beforeunload', () => timeTracker());
            return () => {
                timeTracker();
                window.removeEventListener('beforeunload', () => timeTracker());
            }
        }
    }, [localdata?.user_id != null && commentData?.type_id != null]);

    const timeTracker = async (reloaded: boolean = false) => {
        let time = await CommonShipmentServices.saveTimerApi(
            'renewal',
            commentData?.type_id,
            localdata?.user_id,
            WorkflowStageNames.PHOTO_EDITING,
            reloaded
        );
    }

    return (
        <Layout
            pagename={t('Photo editing')}
            logo={true}
            timer={false}
            comments={commentData}
            hold={true}
        >
            {/* Component to display workflow item information */}
            <WorkflowItemInfo serialNumber={dataentry?.renewal_product_info?.serial_number}
                productType={dataentry?.renewal_product_info?.product_type_title}
                productDescription={dataentry?.renewal_info?.product_description}
                sku={dataentry?.renewal_info?.sku}
                color={dataentry?.renewal_product_info?.color_title || dataentry?.renewal_product_info?.colorlabel}
                size={dataentry?.renewal_product_info?.size_title || dataentry?.renewal_product_info?.sizelabel} />

            <div className="flex-1 overflow-auto">
                <div className="row m-0 h-75">
                    {/* left block start */}
                    <div className="col-6">
                        <div className="py-4 pb-2 px-5 border rounded shadow h-100 d-flex flex-column">
                            {/* <h3 className='text-gray'>{photoEditingInfo?.serial_number}</h3> */}

                            {/* image block start */}
                            <div className={`${styles.photoimg} flex-1 overflow-auto`}>
                                <ImageView
                                    disable={false}
                                    front_image={photoEditingInfo?.front_image}
                                    back_image={photoEditingInfo?.back_image}
                                />
                            </div>
                            {/* image block ends */}

                            {/* Product info block start */}
                            <ProductInfo
                                dataentry={photoEditingInfo}
                            />
                            {/* Product info block end  */}

                        </div>
                    </div>
                    {/* left block ends */}

                    {/* right block starts */}
                    <div className="col-6">
                        <div className="py-4 pb-2 px-5 border rounded shadow h-100 position-relative">
                            <div className={`border rounded w-100 text-center mt-5 py-3`}>
                                <Timer time={totaltime} running={running} className={styles.photostudiotimer} />
                            </div>

                            <div className="d-flex justify-content-center py-5 p-xxl-5 p-xl-3">
                                <div className="d-flex align-items-center justify-content-center w-75 px-xxl-5 px-xl-2">
                                    <span className='cursor-pointer' onClick={handlePausePlay}>{running ? IconData.photostudiopause : IconData.photostudioplay}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* right block ends */}
                </div>
            </div>
            <div className="row m-0">
                <div className="col-6">
                    <Button
                        title={t(CommonConstants.BACK)}
                        className="back-btn shadow-none float-start mt-2"
                        handleClick={handleBack}
                    />
                </div>
                <div className="col-6">
                    {stage == photoEditingInfo?.stage_id &&
                        <Button
                            title={t(CommonConstants.SAVE_AND_SCAN_NEXT)}
                            className="submit-btn shadow-none float-end mt-2"
                            handleClick={handleSubmit}
                        />
                    }
                </div>
            </div>
        </Layout>
    )
}

export default PhotoEditingOrganism;
