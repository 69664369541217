import React, { useState, useEffect } from "react";
import styles from "../../../pages/Shipment/shimpment.module.css";
import { APICALL } from "../../../services/ApiServices";
import {
  fetchList,
  fetchPublishStageItems,
  updatePrepublish
} from "../../../routes/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import customAlert from "../../atoms/CustomAlert";
import {
  CommonConstants,
  ConfigConstants,
  PublishTabs,
  REJECTEDITEMS,
} from "../../../pages/Shipment/Constants/ShipmentConstants";
import TabsView from "../../molecules/TabsView";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import { t } from "../../../pages/Translations/TranslationUtils";
import MerchRackItem from "../../molecules/PrePublishMolecules/MerchRackItem";
import Popup from "../../../utils/popup";
import ScreenDisable from "../../../utils/ScreenDisable";
import PublishListPopup from "./PublishListPopup";
import TableStructure from "../../atoms/TableStructure";
import { getMediaLibraries } from "../../../services/MediaLibraryFolderServices";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import CommonServices from "../../../services/CommonServices";
import WindowHeightComponent from "../../../commonComponents/WindowHeightComponent";
import Layout from "../../../pages/Shipment/Layout";
import { WorkflowStageNames } from "../../../utils/constants/WorkflowStageNames";
import { useUserContext } from "../../../routes/Contextlib";

const PublishingOverviewOrganism: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const localdata = CommonShipmentServices.getPostData();
  const urlParams = new URLSearchParams(window.location.search);
  const bpdetails = JSON.parse(localStorage.getItem("bp_details") ?? "{}");
  const bpid = bpdetails.brand_partner_id;
  const rack = urlParams.get('id');
  const searchtab: any = urlParams.get('tab');
  const [producttype, setProductType] = useState<any>({})
  const [configdata, setconfigdata] = useState({
    imageslist: [],
    tiers: null as any,
    merchRacks: null as any,
    productType: [],
  })
  const [state, setState] = useState({
    activetab: searchtab != "" ? searchtab : "active",
    records: null as any,
    selectedDataEntryIds: [] as any,
    selectAllItems: false,
    showPopup: false,
    timerrunning: true,
    publishedandrejecteditems: {
      view: false,
      published: 0,
      rejected: 0
    }
  });

  useEffect(() => {
    const fetchAllConfig = async () => {
      let configresponse = await fetchProductImage();
      let mediaresponse = await fetchMedia();
      setconfigdata({
        productType: configresponse["ProductType"],
        imageslist: mediaresponse,
        tiers: configresponse["ProductTier"],
        merchRacks: configresponse.cd_merch_rack,
      })
    }
    if (configdata.tiers == null) {
      fetchAllConfig()
    }
  }, [rack]);

  useEffect(() => {
    if (user != null) {
      timeTracker(true);

      window.addEventListener('beforeunload', () => timeTracker());

      return () => {
        timeTracker();
        window.removeEventListener('beforeunload', () => timeTracker());
      };
    }
  }, [user != null]);

  const timeTracker = async (reloaded: boolean = false) => {
    let time = await CommonShipmentServices.saveTimerApi(
      'bp',
      localdata?.brand_partner_id,
      user?.user_id,
      WorkflowStageNames.PREPUBLISH,
      reloaded
    );
  }

  useEffect(() => {
    if (state.records == null) {
      fetchItems()
    }
  }, [])

  useEffect(() => {
    fetchItems()
  }, [state.activetab])

  const fetchItems = async (status = state.activetab) => {
    try {
      const postData = {
        bpid: bpid,
        status: status,
        // status: "active",
        merch_rack: rack,
      };
      const response = await APICALL.service(
        fetchPublishStageItems,
        "POST",
        postData
      );

      setState((prevState: any) => ({
        ...prevState,
        records: response?.data?.map((item: any) => {
          if (item?.renewal_record_wms_info?.rejected_reason) {
            return {
              ...item,
              rejected_reason: CommonServices.stringFormat(item?.renewal_record_wms_info?.rejected_reason),
              rejected_date: CommonServices.showDateBasedOnFormat(item?.renewal_record_wms_info?.updated_at ?? "", user?.date_format ?? 'dd-MM-yyyy')
            };
          }
          return item;
        }),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMedia = async () => {
    let media: any;
    if (configdata.imageslist.length > 0) {
      media = configdata.imageslist;
    } else {
      let mediaresponse = await getMediaLibraries({
        mediabrandpartnerid: bpid,
      });
      return mediaresponse;
    }
    return media;
  };

  const fetchProductImage = async () => {
    try {
      let config = {
        method: "POST",
        data: {
          bpwiselist: [ConfigConstants.ALL_BPWISE_MERCH_RACK],
          list: [ConfigConstants.PRODUCTTIER, ConfigConstants.PRODUCTTYPE],
          bp_id: [bpid],
        },
      };
      const configresponse = await APICALL.service(
        fetchList,
        "POST",
        config,
        true
      );
      if (configresponse?.status === 200) {
        return configresponse?.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  };
  const tabSwitch = async (key: any) => {
    if (state.timerrunning) {
      setState((prevState: any) => ({
        ...prevState,
        activetab: key,
        selectAllItems: false,
        selectedDataEntryIds: [],
      }));
      await handleTabClick(key);
      await fetchItems(key);
    }
  };

  const handleTabClick = async (tab: any) => {
    if (state.timerrunning) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("tab", tab);
      navigate({ search: searchParams.toString() });
    }
  };

  const handleChange = (dataEntryId: number) => {
    if (state.timerrunning) {
      setState((prevState: any) => {
        let updatedDataEntryIds: number[];
        if (prevState.selectedDataEntryIds.includes(dataEntryId)) {
          updatedDataEntryIds = prevState.selectedDataEntryIds.filter(
            (id: any) => id !== dataEntryId
          );
        } else {
          updatedDataEntryIds = [...prevState.selectedDataEntryIds, dataEntryId];
        }

        return {
          ...prevState,
          selectedDataEntryIds: updatedDataEntryIds,
          selectAllItems: areAllItemsSelected(updatedDataEntryIds),
        };
      });
    }
  };

  // Function to check if all items are selected
  const areAllItemsSelected = (updatedDataEntryIds: number[]) => {
    return updatedDataEntryIds.length == state.records.length ? true : false;
  };

  //To handle popup
  const showPopup = (serial_number?: string) => {
    if (state.timerrunning) {
      if (serial_number) {
        setState((prevState) => ({
          ...prevState,
          selectedDataEntryIds: [serial_number],
          showPopup: !prevState.showPopup,
          selectAllItems: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showPopup: !prevState.showPopup,
        }));
      }
    }
  };

  const handleSaveAndNextClick = async () => {
    try {
      if (state.timerrunning) {
        if (state.selectedDataEntryIds?.length > 0) {

          const postdata = {
            // shipment_id: shipmentDetails?.shipment_id,
            bpname: bpdetails.title,
            brand_partner_id: bpdetails?.brand_partner_id,
            serial_numbers: state.selectedDataEntryIds
          }
          // console.log(postdata);
          const response = await APICALL.service(updatePrepublish, 'POST', postdata)
          showPopup();

          if (response?.status === 200) {
            // await fetchData();
            setState((prevState) => {
              let updatedDataEntryIds = [];
              if (state.selectedDataEntryIds.length === state.records.length) {
                updatedDataEntryIds = [];
              } else {
                // Otherwise, select all unselected items
                updatedDataEntryIds = state.records.filter(
                  (item: any) => {
                    return !state.selectedDataEntryIds.includes(item.serial_number);
                  }
                );
              }
              return {
                ...prevState,
                records: updatedDataEntryIds,
                selectedDataEntryIds: [],
                publishedandrejecteditems: {
                  ...prevState.publishedandrejecteditems,
                  rejected: response.data.rejected,
                  published: response.data.published,
                  view: true,
                }
              };
            });

          }
        } else {
          customAlert("error", t("No item selected"), 6000);
          showPopup();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePopup = (status: boolean) => {
    if (state.timerrunning) {
      setState((prevState) => {
        let tabstat = state.activetab;
        if (status) {
          tabstat = 'rejected'
        }
        return {
          ...prevState,
          activetab: tabstat,
          publishedandrejecteditems: {
            ...prevState.publishedandrejecteditems,
            rejected: 0,
            published: 0,
            view: false,
          }
        }
      });

      if (status) {
        navigate('/publish/rack?id=' + rack + '&tab=rejected')
      }
    }
  };

  const handleView = (value: any) => {
    if (state.timerrunning) {
      navigate("/publish/rejected?edit=" + value.renewal_id);
    }
  };

  //To hadle check box
  const toggleSelectAll = () => {
    if (state.timerrunning) {
      let updatedDataEntryIds = [];
      setState((prevState) => {
        if (state.selectedDataEntryIds.length === state.records.length) {
          // If all unselected items are already selected, unselect them
          updatedDataEntryIds = [];
        } else {
          // Otherwise, select all unselected items
          const unselectedItemIds = state.records.map(
            (item: any) => item.serial_number
          );
          updatedDataEntryIds = unselectedItemIds;
        }
        return {
          ...prevState,
          selectedDataEntryIds: updatedDataEntryIds,
          selectAllItems: areAllItemsSelected(updatedDataEntryIds),
        };
      });
    }
  };

  const setTimerRunning = (timer: boolean) => {
    setState((prevState: any) => ({ ...prevState, timerrunning: timer }));
  }

  return (
    <Layout
      pagename="Publishing"
      logo={true}
      timer={true}
      ongoing
      totaltime={0}
      stage={WorkflowStageNames.PREPUBLISH}
      setTimerRunning={setTimerRunning}
    >
      <>
        <div className="flex-1 overflow-auto">
          <div className="position-relative h-100">
            <ScreenDisable display="none" />
            <div className="d-flex justify-content-between py-1">
              <h5 className="fw-bold">{configdata?.merchRacks && CommonShipmentServices.getFilteredTitle(configdata?.merchRacks ?? [], 'merch_rack_id', rack, 'label')}</h5>

              {state.activetab == "active" && (
                <div className="form-check mx-2">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    checked={state.selectAllItems}
                    onChange={toggleSelectAll}
                  />
                  <label className="form-check-label">{t("Select all")}</label>
                </div>
              )}
            </div>
            <div className="py-2">
              <TabsView
                list={PublishTabs}
                tabSwitch={tabSwitch}
                activetab={state.activetab}
              />
            </div>
            {state.activetab == "rejected" ? (
              <div className="">
                {state.records != null ?
                  (<TableStructure
                    isAction
                    data={state.records}
                    headers={REJECTEDITEMS.REJECTED_TABLE_HEADERS}
                    values={REJECTEDITEMS.REJECTED_TABLE_VALUES}
                    handleView={handleView}
                  />)
                  : (
                    <div className="ps-2 py-2">No {state.activetab} records found</div>
                  )}
              </div>
            )
              : (state.records != null && state.records.length > 0 && configdata.tiers != null) ? (
                <div className={`${styles.publishingCards}`}>
                  <div className="d-flex flex-wrap">
                    {state.records.map((item: any, index: any) => (
                      <div key={index} className={`${styles.prepublish_card_block} p-3 `}>
                        <MerchRackItem
                          key={index}
                          item={item}
                          state={state}
                          handleChange={handleChange}
                          index={index}
                          showPopup={showPopup}
                          configdata={configdata}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="ps-2 py-2">No {state.activetab} records found</div>
              )}
          </div>
        </div>
        <div className="mt-1">
          <ActionButtonGroup
            saveAndNextTitle={(state.activetab == "active" && state?.records?.length) ? t("Publish") : ""}
            backTitle={t(CommonConstants.BACK)}
            handleBackClick={() => navigate("/publishing")}
            handleSaveAndNextClick={() => showPopup()}
          />
        </div>
        {state.showPopup && (
          <Popup
            title={""}
            body={t("Are you sure you want to publish?")}
            cancel={() =>
              setState((prevState) => ({ ...prevState, showPopup: false, selectedDataEntryIds: [] }))
            }
            submit={() => handleSaveAndNextClick()}
            yestext={t("Yes, publish")}
            notext={t("No, hold on")}
          />
        )}
        {state.publishedandrejecteditems.view && (
          <PublishListPopup
            published={state.publishedandrejecteditems.published}
            rejected={state.publishedandrejecteditems.rejected}
            handleSubmit={handlePopup}
          />
        )}
      </>

    </Layout>
  );
};

export default PublishingOverviewOrganism;
